import dayjs from "dayjs";
import numeral from "numeral";
import { ParsedUrlQuery } from "querystring";
import {
  AllTasksResponse,
  CustomObject,
  CustomerStatementTransactionResponse,
  DataObject,
  PerformanceChartDataType,
  ReactSelectOption,
  TransactionType,
  UserRole,
} from "../models";
import { BRAND_URL, MONTHS, TELCOS } from "@/constants";
import { getReasonPhrase } from "http-status-codes";
import XLSX from "sheetjs-style";
import * as Yup from "yup";

export const isSuccessfulHttpStatus = (value: number | string) => {
  return ["200", "201"].includes(`${value}`);
};

export const getURLFirstPath = (url: string) => {
  return new URL(url, location.origin || BRAND_URL).pathname.split("/")[1];
};

export const getSubmitBtnStatus = (isLoading: boolean, slug: string = "") => {
  if (isLoading && slug) {
    return "Updating...";
  }
  if (isLoading && !slug) {
    return "Adding...";
  }
  if (!isLoading && slug) {
    return "Update";
  }

  return "Add";
};

export const trimText = (text: string, length: number) => {
  if (text?.length > length) {
    return `${text.substring(0, length)}...`;
  }
  return text;
};

export const splitByDash = (text: string) => {
  const newValue = text?.split(" - ")[1];
  return newValue;
};

export const formatDate = (date: Date | string | undefined) => {
  return date
    ? new Date(date).toLocaleDateString("en-US", {
        weekday: "short",
        month: "short",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        timeZone: "UTC",
      })
    : "";
};

export const formatCustomDate = (date: Date | string) => {
  return new Date(date).toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    day: "numeric",
    month: "short",
    year: "numeric",
  });
};

export const getCurrentFormattedDate = () => {
  const now = new Date();

  const year = now.getUTCFullYear();
  const month = String(now.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const day = String(now.getUTCDate()).padStart(2, "0");

  return `${year}-${month}-${day}T00:00:00.000Z`;
};

export const previousPage = {
  previousPageID: "previousPageID",
  currentPageID: "currentPageID",
  get() {
    this.update();
    const previousPage = sessionStorage.getItem(this.previousPageID);
    return previousPage;
  },
  update() {
    const previousPage = sessionStorage.getItem(this.previousPageID);
    const currentPage = sessionStorage.getItem(this.currentPageID);

    // firstTimer
    if (!currentPage) {
      sessionStorage.setItem(this.previousPageID, location.href);
      sessionStorage.setItem(this.currentPageID, location.href);
    } else if (currentPage !== location.href) {
      sessionStorage.setItem(this.previousPageID, currentPage);
      sessionStorage.setItem(this.currentPageID, location.href);
    }

    return previousPage;
  },
};

const getObjectValue = (
  obj: DataObject,
  key: string,
  ignoreCase: boolean = true
) => {
  // ignores casing ie) uppercase or lowercase keys
  if (ignoreCase) {
    const lowerCaseKey = key.toLowerCase();
    const keys = Object.keys(obj);

    for (const element of keys) {
      if (element.toLowerCase() === lowerCaseKey) {
        return obj[element];
      }
    }

    return null; // Key not found
  }

  return obj[key];
};

export const getFormReferer = (data: DataObject): string | null => {
  if (previousPage.get()) {
    const previousUrl = new URL(previousPage.get()!);
    previousUrl.searchParams.delete("page");

    const doesPreviousURLHaveParams = Object.keys(
      previousUrl.searchParams
    ).length;

    if (
      previousUrl.pathname !== location.pathname &&
      location.pathname.includes(previousUrl.pathname) &&
      doesPreviousURLHaveParams
    ) {
      const searchParams = previousUrl.searchParams;

      Array.from(searchParams.keys()).forEach((key) => {
        const value = getObjectValue(data, key);

        if (
          value &&
          !`${value}`
            ?.toLowerCase()
            .includes(searchParams.get(key)?.toLowerCase() || "")
        ) {
          searchParams.set(key, value);
        }
      });

      return previousUrl.toString();
    }
  }

  return null;
};

export function getRelativeTime(date: Date): string {
  const currentDate = new Date();
  const timeDifference = currentDate.getTime() - date.getTime();

  if (timeDifference < 60000) {
    // less than a minute
    return "Just now";
  } else if (timeDifference < 3600000) {
    // less than an hour
    return `${Math.floor(timeDifference / 60000)} minute(s) ago`;
  } else if (timeDifference < 86400000) {
    // less than a day
    return `${Math.floor(timeDifference / 3600000)} hour(s) ago`;
  } else if (timeDifference < 604800000) {
    // less than a week
    return `${Math.floor(timeDifference / 86400000)} day(s) ago`;
  } else if (timeDifference < 2592000000) {
    // less than a month
    return `${Math.floor(timeDifference / 604800000)} week(s) ago`;
  } else if (timeDifference < 31536000000) {
    // less than a year
    return `${Math.floor(timeDifference / 2592000000)} month(s) ago`;
  } else {
    return `${Math.floor(timeDifference / 31536000000)} year(s) ago`;
  }
}

export const copyText = (url: string) => {
  navigator.clipboard.writeText(`${url}`);
};

export const share = (data: any) => {
  navigator.share(data);
};

export const capitalize = (word: string) => {
  return (
    word
      ?.split(/(?=[A-Z])/)
      ?.map((word) => word[0]?.toUpperCase() + word.slice(1))
      ?.join(" ") || word
  );
};

export const stringifyURLQuery = (urlQuery: ParsedUrlQuery) => {
  return Object.keys(urlQuery)
    .filter((key) => !["slug", "page"].includes(key))
    .map((key) => `${key}=${urlQuery[key]}&`)
    .join("");
};

export const changeFormDataToURLParams = (formData: FormData) => {
  const params: string[] = [];

  formData.forEach((value, key) => {
    value && params.push(`${key}=${value}&`);
  });

  return params.join("");
};

export const isGHPhoneNumber = (value: string) => {
  const isPhone = /^(233|0|00233|\+233)(\s|-)?\d{9}$/.exec(value);
  return isPhone;
};

export const isEmail = (value: string) => {
  const isEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.exec(value);
  return isEmail;
};

export const isRole = (role: string) => {
  const isRole = /^(cashier|administrator|staff)$/i.exec(role);
  return isRole;
};

export const changeToTitleCase = (str?: string | number | null): string => {
  if (!str) {
    return "";
  }

  const titleCase = (word: string) =>
    word?.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());

  return str
    ?.toString()
    .split(/[_-]/)
    .map((word) => titleCase(word))
    .join("_");
};

export const changeDateToDefaultFormat = (dateString: string) => {
  if (new Date(dateString)?.getTime()) {
    return new Date(dateString).toDateString();
  }

  const dateArr = dateString.replaceAll("/", "-").split("-");
  dateArr[1] = parseInt(dateArr[1])
    ? MONTHS[parseInt(dateArr[1]) - 1]
    : dateArr[1];

  const date = new Date(dateArr.join("-"));

  return date.toDateString();
};

export const changeDateToBackendFormat = (dateString: string) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear().toString().substring(-2);

  return `${day}-${MONTHS[monthIndex]}-${year}`;
};

export const getPreviousMonth = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();

  let previousYear = year;
  let previousMonth = month - 1;

  if (previousMonth < 0) {
    previousYear--;
    previousMonth = 11;
  }

  return `${previousYear}${(previousMonth + 1).toString().padStart(2, "0")}`;
};

export const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const getBasePath = (url: string) => {
  return new URL(url, BRAND_URL).pathname;
};

export const formatPaymentChannel = (paymentChannel: string) => {
  switch (paymentChannel?.toLowerCase()) {
    case "mobilemoney":
      return "Mobile Money";
    case "card":
      return "Card";
    case "ghana.gov":
      return "Ghana.gov";
    default:
      return paymentChannel;
  }
};

export const changeDateToInputDateFormat = (value: string = "") => {
  if (value) {
    try {
      const date = new Date(value);
      if (date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");

        return `${year}-${month}-${day}`;
      }
      return date;
    } catch (err) {
      console.log(err);
      return value;
    }
  }
  return "";
};

export const isLessThan10Minutes = (value = "") => {
  try {
    const differenceInMilliseconds = Math.abs(
      new Date().getTime() - new Date(value).getTime()
    );
    const differenceInMinutes = differenceInMilliseconds / (1000 * 60);

    return differenceInMinutes < 10;
  } catch (err) {
    return false;
  }
};

export function generateNamesSummary(names: string[] | []) {
  if (names.length === 0) {
    return "";
  } else if (names.length === 1) {
    return names[0];
  } else if (names.length === 2) {
    return names.join(" and ");
  } else {
    const firstThree = names.slice(0, 2).join(", ");
    const remainingCount = names.length - 2;
    return `${firstThree} and ${remainingCount} others`;
  }
}

export const generateSelectedOptionsTitle = (
  options: ReactSelectOption[],
  query: string,
  defaultLabel: string
) => {
  if (query) {
    const values = query.split(",");
    const selectedOptionLabels = values.map((value) => {
      const matchingOption = options.find((option) => option.value === value);
      return matchingOption?.label!;
    });
    return generateNamesSummary(selectedOptionLabels);
  }
  return defaultLabel;
};

export function convertBooleanToYesNo(value: boolean) {
  return value ? "Yes" : "No";
}

export function convertDateToZeroBasedFormat(inputDate: string): string {
  const date = new Date(inputDate);

  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();

  return `${day}.${month}.${year}`;
}

export function formatMonthYear(inputDate: string): string {
  const [monthStr, year] = inputDate.split("-");
  const monthNumber = parseInt(monthStr, 10);

  const monthName = new Date(`${monthNumber}-01-2000`).toLocaleString("en-US", {
    month: "long",
  });

  return `${monthName}, ${year}`;
}

export function debounce(func: any, timeout: number) {
  let timer: any;
  return function (this: any, ...args: any[]) {
    clearTimeout(timer);
    timer = setTimeout(() => func.apply(this, args), timeout);
  };
}

export function formatDateProperly(dateString: string): string {
  const [day, month, year] = dateString.split(".");

  // Function to get the ordinal suffix for the day
  const getOrdinalSuffix = (number: number): string => {
    if (number >= 11 && number <= 13) {
      return "th";
    }
    switch (number % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  // Function to format the month name
  const getMonthName = (monthIndex: number): string => {
    const months: string[] = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return months[monthIndex - 1];
  };

  // Format the date
  return `${day}${getOrdinalSuffix(parseInt(day))}, ${getMonthName(
    parseInt(month)
  )}, ${year}`;
}

export const getHttpStatusDescription = (status: number | string) => {
  return getReasonPhrase(status);
};

export const isEmpty = (value: unknown) => {
  return (
    value == null || (typeof value === "string" && value.trim().length === 0)
  );
};

export const isMoreThanTenMinutesApart = (time: string): boolean => {
  const date1 = new Date(time);
  const date2 = new Date();

  const difference = Math.abs(date2.getTime() - date1.getTime());

  const differenceInMinutes = difference / 1000 / 60;

  return differenceInMinutes > 10;
};

export const checkVenderAccountId = (value: string | null): boolean => {
  const validVendorAccountIds = ["MTN", "AIRTELTIGO", "VODAFONE", "EXPRESSPAY"];
  return value !== null && validVendorAccountIds.includes(value);
};

export const generateExcelFile = async (
  encodedData: string,
  fileName: string
) => {
  // Replace "your_encoded_data" with the actual data stream
  const uint8Array = new Uint8Array(
    window
      .atob(encodedData)
      .split("")
      .map((char) => char.charCodeAt(0))
  );

  // Create a new WorkBook
  const workbook = XLSX.read(uint8Array, { type: "array" });

  // Get the first worksheet
  const worksheet = workbook.Sheets[workbook.SheetNames[0]];

  // Convert the worksheet data to JSON
  const jsonData = XLSX.utils.sheet_to_json(worksheet);

  // Create a new Workbook with the JSON data
  const newWorkbook = XLSX.utils.book_new();
  const newWorksheet = XLSX.utils.json_to_sheet(jsonData);
  XLSX.utils.book_append_sheet(newWorkbook, newWorksheet, "Sheet1");

  // Generate the Excel file
  const excelData = XLSX.write(newWorkbook, {
    bookType: "xlsx",
    type: "array",
  });

  const excelBlob = new Blob([excelData], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const excelUrl = URL.createObjectURL(excelBlob);

  // Create a temporary link and click it to initiate the file download
  const link = document.createElement("a");
  link.href = excelUrl;
  link.download = `${fileName}.xlsx`;
  link.click();
};

export const formatAmount = (value?: number) => {
  return numeral(value ?? 0).format("0,0.00");
};

export const formatNumber = (value?: number) => {
  return numeral(value ?? 0).format("0,0");
};

export const formatAmountChart = (value?: number) => {
  if (value === undefined || value === null) return "0";

  const formatWithSuffix = (num: number, suffix: string) => {
    const formatted = num.toFixed(2);
    return formatted.endsWith(".00")
      ? `${formatted.slice(0, -3)}${suffix}`
      : `${formatted}${suffix}`;
  };

  if (value >= 1_000_000_000) {
    return formatWithSuffix(value / 1_000_000_000, "B"); // Billions
  } else if (value >= 1_000_000) {
    return formatWithSuffix(value / 1_000_000, "M"); // Millions
  } else if (value >= 1_000) {
    return formatWithSuffix(value / 1_000, "K"); // Thousands
  }
  return numeral(value).format("0,0.00");
};

export const titleCase = (str?: string | null) => {
  return changeToTitleCase(str);
};

export function getCompletedAssignedChartsMonthsLabel(
  chartData: PerformanceChartDataType[] | null
) {
  const seenMonths: string[] = [];

  return chartData
    ?.filter(
      (_, index) => chartData[index]?.week !== chartData[index + 1]?.week
    )
    ?.map(({ month }) => {
      if (!seenMonths.includes(month)) {
        seenMonths.push(month);
        return month;
      }

      return "";
    });
}

export function getCompletedAssignedChartsMonthsValue(
  chartData: PerformanceChartDataType[] | null
) {
  const seenMonths: string[] = [];

  return chartData
    ?.filter(
      (_, index) => chartData[index]?.week !== chartData[index + 1]?.week
    )
    ?.map(({ completed, assigned }) => {
      return { completed, assigned };
    });
}

export const sieveObjectFalsyValues = <T = unknown>(
  params: CustomObject<T>
) => {
  return Object.fromEntries(
    Object.entries(params).filter(([_key, value]) => value)
  );
};

interface DataItem {
  month: string;
  week: string;
  totalPaid: number;
  totalFulfilled: number;
  totalFailed: number;
  totalRefunded: number;
  weekStartDate: string;
}

export function getLastTwoMonthsData(data: DataItem[]): DataItem[] {
  // Get the current date
  const currentDate = new Date();

  // Get the current month (0-based index)
  const currentMonthIndex = currentDate.getMonth();

  // Create a map to get month names from index
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Get the current month name
  const currentMonth = monthNames[currentMonthIndex];

  // Get the previous month name
  const previousMonthIndex = (currentMonthIndex - 1 + 12) % 12;
  const previousMonth = monthNames[previousMonthIndex];

  // Filter the data for the last two months
  const result = data.filter(
    (item) => item.month === currentMonth || item.month === previousMonth
  );

  return result;
}

export function getLastMonthData(data: DataItem[]): DataItem[] {
  // Parse the dates and sort the data by weekStartDate
  const sortedData = [...data].sort(
    (a, b) =>
      new Date(a.weekStartDate).getTime() - new Date(b.weekStartDate).getTime()
  );

  // Get the most recent date
  const mostRecentDate = new Date(
    sortedData[sortedData.length - 1]?.weekStartDate
  );

  // Calculate the last month
  const lastMonthIndex = (mostRecentDate.getMonth() - 1 + 12) % 12;
  const lastMonthYear =
    lastMonthIndex === 11
      ? mostRecentDate.getFullYear() - 1
      : mostRecentDate.getFullYear();

  // Filter the data for the last month
  const result = sortedData.filter((item) => {
    const itemDate = new Date(item.weekStartDate);
    const itemMonthIndex = itemDate.getMonth();
    const itemYear = itemDate.getFullYear();
    return itemMonthIndex === lastMonthIndex && itemYear === lastMonthYear;
  });

  return result;
}

export function getThisMonthData(data: DataItem[]): DataItem[] {
  // Parse the dates and sort the data by weekStartDate
  const sortedData = [...data].sort(
    (a, b) =>
      new Date(a.weekStartDate).getTime() - new Date(b.weekStartDate).getTime()
  );

  // Get the most recent date
  const mostRecentDate = new Date(
    sortedData[sortedData.length - 1]?.weekStartDate
  );

  // Calculate the current month
  const currentMonthIndex = mostRecentDate.getMonth();
  const currentMonthYear = mostRecentDate.getFullYear();

  // Filter the data for the current month
  const result = sortedData.filter((item) => {
    const itemDate = new Date(item.weekStartDate);
    const itemMonthIndex = itemDate.getMonth();
    const itemYear = itemDate.getFullYear();
    return (
      itemMonthIndex === currentMonthIndex && itemYear === currentMonthYear
    );
  });

  return result;
}

export const getTaskStatus = (task: AllTasksResponse | null) => {
  if (task?.discardStatus === "approved") {
    return "Discarded";
  }

  if (task?.status === "completed") {
    return "Completed";
  }

  if (task?.isOverdue) {
    return "Overdue";
  }

  if (task?.discardStatus === "pending") {
    return "Pending Discard";
  }

  if (task?.status === "new") {
    return "Not Started";
  }

  if (task?.status === "inprogress") {
    return "In Progress";
  }

  return null;
};

export const formatTopupChannel = (value: string) => {
  if (value === "mobilemoney") {
    return "Mobile Money";
  }

  return value;
};

export const getStepSize = (total: number): number => {
  if (total <= 10) {
    return 1;
  }

  // Round the total to the nearest 10
  const roundedTotal = Math.round(total / 10) * 10;

  // Calculate the value that when added 10 times will sum up to the rounded total
  let value = roundedTotal / 10;

  // Round the value to the nearest 10
  value = Math.round(value / 10) * 10;

  return value;
};

export const formatIdentificationTypes = (value: string) => {
  const IDTypes: CustomObject<string> = {
    GHANACARD: "Ghana Card",
    PASSPORT: "Passport",
    VOTERSIDENTIFICATIONCARD: "Voters Identification Card",
    DRIVERSLICENSE: "Drivers License",
    NHIS: "NHIS",
  };

  return IDTypes[value?.toUpperCase()] || value;
};

export const formatServiceRequestType = (type: string) => {
  return type.replace(/([A-Z])/g, " $1").trim();
};

export const verifyRole = ({
  userRole,
  includedIn,
}: {
  userRole: string;
  includedIn: UserRole[];
}) => {
  return [...includedIn, "qa"]?.includes(userRole?.toLowerCase() as UserRole);
};

export function formatUserRole(role?: string) {
  if (role === "qa") return "QA";
  return titleCase(role?.replaceAll("-", " "));
}

export const getTruthyValue = <T, U>(initial: T, fallback: U) => {
  return initial || fallback;
};

export const isArrayIncludes = <T>(arr: T[], value: T) => {
  return arr.includes(value);
};

export const trim = (value: string) => {
  return (value ?? "").trim();
};

const percentageIncrease = (yesterday: number, today: number): number => {
  let percent: number;

  if (today !== 0) {
    if (yesterday !== 0) {
      percent = ((today - yesterday) / yesterday) * 100;
    } else {
      percent = today * 100;
    }
  } else {
    percent = -yesterday * 100;
  }

  return Math.floor(percent);
};

export const calculatePercentChange = (
  yesterdayValue: number,
  todayValue: number
) => {
  const percentChange = percentageIncrease(yesterdayValue, todayValue);
  if (percentChange > 100) {
    return 100;
  } else if (percentChange < -100) {
    return -100;
  } else {
    return percentChange;
  }
};

export const currency = (value: FormatValue) => {
  if (value === undefined) return null;
  if (isNaN(Number(value))) {
    return "0.00";
  }
  const fixedValue = toTwoDecimals(value);
  return numeral(fixedValue).format("0,0.00");
};

const toTwoDecimals = (value: FormatValue) => {
  if (value === undefined) return null;
  let valueStr = String(value);
  let decimalIndex = valueStr.indexOf(".");
  if (decimalIndex !== -1) {
    valueStr = valueStr.slice(0, decimalIndex + 3);
  }
  return Number(valueStr);
};

type FormatValue = string | number | undefined;

export const getDurationFilterLabel = (
  filters: ReactSelectOption[],
  filterValue: string
) => {
  return filters.find(({ value }) => value === filterValue)?.label ?? "";
};

export const formatDurationLabelsOnChart = (prop: {
  type: string;
  value: string;
  index: number;
  date: string;
}) => {
  if (prop.type === "last seven days") {
    return getFormattedDate(prop.date, "dddd");
  }

  return getFormattedDate(prop.date, "DD MMM, YYYY");
};

type DateFormat =
  | "DD MMM, YYYY"
  | "D MMM, YYYY • h:mm A"
  | "MMM D, YYYY • h:mm A"
  | "MMM D , YYYY • h:mm A"
  | "DD/MM/YYYY"
  | "MMMM D, YYYY • h:mm"
  | "dddd • MMMM D - h:mm a"
  | "dddd, h:mm a"
  | "MMMM D, YYYY • h:mm A"
  | "MMMM D, YYYY"
  | "D MMMM, YYYY"
  | "MMMM YYYY"
  | "MMMM"
  | "dddd"
  | "YYYY-MM-DD"
  | "YYYY";

export const getFormattedDate = (
  date?: string | null,
  format: DateFormat = "YYYY-MM-DD" // Default format
): string => {
  if (!date) return ""; // Handle undefined or null
  dayjs.locale("en");
  return dayjs(date).format(format); // Treat the input as UTC implicitly
};

export const durationOptions = [
  { label: "Last 7 days", value: "last seven days" },
  { label: "This Month", value: "this month" },
  { label: "Last Month", value: "last month" },
  { label: "Last 3 Months", value: "last three month" },
  { label: "Last 6 Months", value: "last six month" },
];

export const getChallengedTransactionAvailableActions = ({
  transaction,
  role,
}: {
  transaction: TransactionType | CustomerStatementTransactionResponse | null;
  role: string;
}) => {
  const isTelco = TELCOS.includes(
    transaction?.vendorAccountId?.toUpperCase?.() ?? ""
  );
  const allowedRoles = ["national-security", "qa", "administrator", "staff"];
  const allowedSingleRetryRoles = ["qa", "administrator"];

  // Check if the user has the necessary role to perform actions
  if (!allowedRoles.includes(role)) {
    return null;
  }

  if (transaction?.isChallenged && transaction?.status === "failed" && isTelco) {
    return {
      canRefund: true,
    };
  }

  if (
    transaction?.isChallenged &&
    transaction?.meterCategory.toLocaleLowerCase() === "postpaid" &&
    (transaction?.status === "failed" || transaction?.status === "paid") &&
    allowedSingleRetryRoles.includes(role)
  ) {
    return {
      canRetry: true,
    };
  }

  if (
    transaction?.isChallenged &&
    transaction?.status === "failed" &&
    !isTelco
  ) {
    return {
      canRetry: true,
      canRefund: true,
    };
  }

  if (transaction?.isChallenged && transaction.status === "refund pending") {
    return {
      canComplete: true,
    };
  }

  return null;
};

export const formatDateFilters = (key: string, value: string) => {
  if (!value) {
    return value;
  }

  if (key?.toLowerCase() === "enddate") {
    const date = new Date(value);
    date.setUTCHours(23, 59, 59, 59);
    return date.toISOString();
  }

  return new Date(value).toISOString();
};

export const keyGen = (index: number) => {
  return "key-" + index;
};

export const parseDateSafely = (value: any): Date | null => {
  if (!value) return null;
  const date = new Date(value);
  return isNaN(date.getTime()) ? null : date;
};

export const securityPatterns = {
  script: /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi,
  eventHandlers: /\bon[a-z]+\s*=/gi,

  jsProtocol: /javascript:/gi,
  dataProtocol: /data:/gi,
  vbscript: /vbscript:/gi,

  htmlTags: /<[^>]*>/g,
  sqlInjection:
    /(\b(select|insert|update|delete|drop|union|exec|declare)\b)|(['";])/gi,
} as const;

export const patterns = {
  phone: /^(0|233)\d{9}$/,
  mobileNumber: /^(0|233)\d{9}$/,
  numeric: /^\d+$/,

  alphanumeric: /^[a-zA-Z0-9-]+$/,
  name: /^[A-Za-z\s-]+$/,
  letters: /^[A-Za-z]+$/,
  companyName: /^[a-zA-Z0-9\s-&.]+$/,
} as const;

export const secureString = Yup.string().test(
  "security",
  "Invalid characters or potentially harmful content detected",
  (value) => {
    if (!value) return true;
    return !Object.values(securityPatterns).some((pattern) =>
      pattern.test(value)
    );
  }
);
export const createSecureStringSchema = (
  additionalValidation?: (schema: Yup.StringSchema) => Yup.StringSchema
) => {
  let schema = secureString;
  if (additionalValidation) {
    schema = additionalValidation(schema);
  }
  return schema;
};

export const getStatusStyles = (status: string) => {
  switch (status) {
    case "success":
    case "completed":
      return {
        backgroundColor: "rgba(0, 184, 124, 0.1)",
        color: "#00B87C",
        border: "1px solid #00B87C",
        label: status === "success" ? "Success" : "Completed",
      };
    case "pending":
      return {
        backgroundColor: "rgba(255, 193, 7, 0.1)",
        color: "#FFC107",
        border: "1px solid #FFC107",
        label: "Pending",
      };
    case "failed":
    default:
      return {
        backgroundColor: "rgba(244, 63, 94, 0.1)",
        color: "#F43F5E",
        border: "1px solid #F43F5E",
        label: "Failed",
      };
  }
};
