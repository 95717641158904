import { useSession } from "next-auth/react";
import { eventPublisher } from "@/services/event-publisher";
import { useEffect } from "react";


export default function ObservabilityInit() {
  const { data: session } = useSession();

   useEffect(() => {
     eventPublisher.initObservability();
   }, []);


  useEffect(() => {
    if (session?.user) {
      const userData = session.user;

      eventPublisher.datadogRum.setUser({
        email: userData.email,
        name: userData.name,
      });
    }
  }, [session]);

  return null;
}
