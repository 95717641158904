import pkg from "../package.json";
import { HubtelEventPub } from "@hubtel/event-publisher";

const { version } = pkg || {};
export const eventPublisher = new HubtelEventPub({
  appId: process.env.NEXT_PUBLIC_DATADOG_APP_ID ?? "",
  clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN ?? "",
  appName: process.env.NEXT_PUBLIC_SERVICE_NAME ?? "",
  appVersion: version,
  sectionName: process.env.NEXT_PUBLIC_DATADOG_APP_ID ?? "",
  sendToEventAnalytics: false,
  sendToEventsPortal: false,
  developerMode: process.env.NEXT_PUBLIC_DATADOG_ENV !== "production",
  tags: process.env.NEXT_PUBLIC_OBSERVABILITY_TAGS,
});
